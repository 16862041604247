import React from "react";
import Icon from '../Icons/icon';
import * as S from "./styles";

const WhatsAppButton = () => (
  <S.ButtonContainer
    href="https://wa.me/5561983002735"
    target="_blank"
    rel="noreferrer"
  >
    <Icon name="Whatsapp" />
  </S.ButtonContainer>
);

export default WhatsAppButton;
