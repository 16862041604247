import { graphql, useStaticQuery } from "gatsby";
import PropTypes from "prop-types";
import * as React from "react";

function Seo({ description = "", title, children }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            titleMedium
            titleLong
            description
            author
            image
            siteUrl
          }
          buildTime
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;
  const defaultTitle = site.siteMetadata.title;

  const schema = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "url": site.siteMetadata.siteUrl,
    "name": site.siteMetadata.titleMedium,
    "alternateName": site.siteMetadata.titleLong,
    "headline": site.siteMetadata.titleMedium,
    "description": metaDescription,
    "dateModified": site.buildTime,
    "inLanguage": "pt-BR",
    "potentialAction": [
      {
        "@type": "ReadAction",
        "target": [site.siteMetadata.siteUrl]
      }
    ]
  };

  return (
    <>
      <title>{defaultTitle ? `${defaultTitle} | ${title}` : title}</title>
      <meta name="description" content={metaDescription} />
      <meta name="title" content={site.siteMetadata.titleLong} />
      <meta name="site_name" content={site.siteMetadata.titleMedium} />
      <meta name="url" content={site.siteMetadata.siteUrl} />
      <meta name="image" content={site.siteMetadata.image} />
      <meta name="author" content={site.siteMetadata.author} />
      <meta name="language" content="pt-BR" />
      <meta name="keywords" content="advogados DF, advogados associados Brasília, escritório de advocacia Brasília, assessoria jurídica Distrito Federal, advogados especializados Brasília, consultoria jurídica, GM Advogados, GM Advogados Associados, Guimarães & Medeiros Advogados Associados, escritório de advocacia Brasília, melhores advogados, direito previdenciário DF" />

      <meta property="article:modified_time" content={site.buildTime} />

      <meta name="robots" content="follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large" />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:creator" content={site.siteMetadata.author} />
      <meta name="twitter:title" content={site.siteMetadata.titleMedium} />
      <meta name="twitter:site" content={site.siteMetadata.author} />
      <meta name="twitter:image" content={site.siteMetadata.image} />
      <meta name="twitter:description" content={metaDescription} />
      <meta
        name="twitter:image:alt"
        content="Site especializado em advocacia, a imagem contém a letra G de Guimarães e M de Medeiros, o nome dos advogados associados a qual o site pertence"
      />

      <meta name="msapplication-TileImage" content={site.siteMetadata.image} />

      <meta property="og:site_name" content={site.siteMetadata.title} />
      <meta property="og:title" content={site.siteMetadata.titleMedium} />
      <meta property="og:description" content={metaDescription} />

      <meta property="og:url" content={site.siteMetadata.siteUrl} />
      <meta property="og:type" content="website" />

      <meta property="og:image" content={site.siteMetadata.image} />
      <meta property="og:image:width" content="660" />
	    <meta property="og:image:height" content="660" />

      <meta
        property="og:image:alt"
        content="Site especializado em advocacia, a imagem contém a letra G de Guimarães e M de Medeiros, o nome dos advogados associados a qual o site pertence"
      />
      <meta property="og:image:type" content="image/png" />
      <meta property="og:locale" content="pt_BR" />
      <meta property="og:updated_time" content={site.buildTime} />

      <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }}></script>

      {children}
    </>
  );
}

Seo.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export default Seo;
