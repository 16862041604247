import styled from "styled-components";

export const ButtonContainer = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1001;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  background-color: ${({ theme }) => theme.colors.success};
  box-shadow: 0px 5px 12px 3px rgb(0 0 0 / 10%);
  transition: all 0.25s ease;

  @media screen and (min-width: 1024px) {
    bottom: 30px;
    right: 30px;
    width: 80px;
    height: 80px;
  }

  svg {
    width: 35px;
    height: 35px;
    margin-bottom: 2px;

    @media screen and (min-width: 1024px) {
      width: 50px;
      height: 50px;
      margin-left: 2px;
    }
  }

  :hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.successDark};
  }
`;
