import * as React from "react";
import styled, { css } from "styled-components";
import Seo from "../components/seo";
import WhatsAppButton from "../components/WhatsAppBtn";
import {
  Container,
  H2Base,
  ReturnToHomeButton,
} from "../styles/general-styles";

const CenterLayout = css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
`;

const Section = styled.section`
  height: 50vh;
  padding: 0 1rem;
  ${CenterLayout};

  @media screen and (min-width: 768px) {
    height: 70vh;
  }
`;

const Content = styled(Container)`
  ${CenterLayout};
  margin-bottom: 2rem;

  h2 {
    ${H2Base};
    line-height: 20px;
    color: ${({ theme }) => theme.colors.text};
    margin-top: 0;

    @media only screen and (min-width: 767px) {
      font-size: ${({ theme }) => theme.fontSize.base};
    }
  }
`;

const Content404 = styled.div`
  h1 {
    font-family: ${({ theme }) => theme.font.main};
    font-size: 160px;
    font-weight: 900;
    margin: 0;
    color: ${({ theme }) => theme.colors.text};
    text-transform: uppercase;
    letter-spacing: -40px;
    margin-left: -40px;

    @media only screen and (min-width: 576px) {
      font-size: 250px;
    }
  }

  h1 > span {
    text-shadow: -20px 0 0 ${({ theme }) => theme.colors.background};
  }

  h3 {
    font-size: ${({ theme }) => theme.fontSize.md};
    font-weight: 700;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.text};
    margin: 0;
    letter-spacing: 3px;
    padding-left: 6px;

    @media only screen and (min-width: 576px) {
      font-size: ${({ theme }) => theme.fontSize.base};
    }

    @media only screen and (min-width: 767px) {
      font-size: ${({ theme }) => theme.fontSize.lg};
    }
  }
`;

const NotFoundPage = () => (
  <Section>
    <WhatsAppButton />

    <Content>
      <Content404>
        <h3>Oops! Página não encontrada</h3>
        <h1>
          <span>4</span>
          <span>0</span>
          <span>4</span>
        </h1>
      </Content404>
      <h2>Desculpe, mas a página que você solicitou não foi encontrada!</h2>
    </Content>

    <ReturnToHomeButton to="/">Voltar à página inicial</ReturnToHomeButton>
  </Section>
);

export const Head = () => (
  <Seo title="Direito Previdenciário, Trabalhista e do Consumidor" />
);

export default NotFoundPage;
